<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-04-16 21:56:19
 * @Description: 新增/查看优惠券
-->

<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      labelAlign="left"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 9 }"
    >
      <a-form-model-item label="优惠券名称" prop="couName">
        <a-input
          v-model="form.couName"
          placeholder="请输入优惠券名称"
          :disabled="isViewModel"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="优惠券金额" prop="couPrice">
        <a-input-number
          v-model="form.couPrice"
          maxLength="5"
          :min="0"
          :max="9999999.99"
          :precision="2"
          :disabled="isViewModel"
          placeholder="请输入优惠券金额"
          style="width: 200px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="优惠券限制金额" prop="couponLimitPrice">
        <a-input-number
          v-model="form.couponLimitPrice"
          maxLength="5"
          :min="0"
          :max="9999999.99"
          :precision="2"
          :disabled="isViewModel"
          placeholder="请输入优惠券限制金额"
          style="width: 200px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="领取后过期天数" prop="couponOutDays">
        <a-input-number
          v-model="form.couponOutDays"
          maxLength="5"
          :min="0"
          :max="9999999"
          :disabled="isViewModel"
          placeholder="用户领取时间起，N天后过期，默认为0表示不开启"
          style="width: 200px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="发行量" prop="couponCirculation">
        <a-input-number
          v-model="form.couponCirculation"
          maxLength="5"
          :min="0"
          :max="9999999"
          :disabled="isViewModel"
          placeholder=""
          style="width: 200px"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="适用用户" prop="types" required>
        <a-radio-group
          name="radioGroup"
          :value="form.types"
          :disabled="isViewModel"
          @change="typesChange"
        >
          <a-radio :value="1"> 全部用户 </a-radio>
          <a-radio :value="3"> 指定用户 </a-radio>
          <a-radio :value="2"> 指定商品用户 </a-radio>
        </a-radio-group>
        <a-input
          v-if="form.types == 3"
          v-model="form.canUserNos"
          :disabled="isViewModel"
          placeholder="输入用户手机号，多个用“,”分开"
        ></a-input>

        <a-input
          v-else-if="form.types == 2"
          v-model="form.canUserNos"
          :disabled="isViewModel"
          placeholder="输入商品ID，多个商品ID用“,”分开"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="有效期" required>
        <a-form-item>
          <a-range-picker
            :value="rangePicker"
            :disabled="isViewModel"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="rangePickerChange"
          />
        </a-form-item>
      </a-form-model-item>
      <a-form-model-item
        label="适用商品"
        prop="canUseCourseNos"
        :class="{
          'has-error': tableList.length == 0 && isGoods,
        }"
        required
      >
        <a-button v-if="!isViewModel" type="primary" @click="addGoods"> 添加商品 </a-button>

        <div v-if="tableList.length > 0">
          <div class="p-container">
            <a-list item-layout="horizontal" :data-source="tableList">
              <a-list-item slot="renderItem" slot-scope="item">
                <div class="info-wrap flexbox middle justify">
                  <div class="item-info flexbox flex1">
                    <img
                      class="p-img"
                      :src="item.coverUrl ? item.coverUrl : item.coverImg"
                      alt=""
                    />
                    <div class="p-info flex1">
                      <div class="p-wrap">
                        <div class="p-info-name more-t">
                          {{ item.name ? item.name : item.memberName }}
                        </div>
                        <!-- <div class="p-info-price">￥{{ item.price }}</div> -->
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 0"
                          class="p-info-price"
                        >
                          免费
                        </div>
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 1"
                          class="p-info-price"
                        >
                          ￥{{ item.price.toFixed(2) }}
                        </div>
                        <div
                          v-show="item.courseType == 1 && item.pricingType == 2"
                          class="p-info-price"
                        >
                          不支持单独售卖
                        </div>
                        <div v-show="item.courseType != 1" class="p-info-price">
                          ￥{{ item.price.toFixed(2) }}
                        </div>
                      </div>
                      <div class="p-info-type">
                        商品类型 ：{{
                          item.courseType == 1 ? "课程" : "会员"
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!isViewModel">
                    <a @click="delHandle(item)"
                      ><a-icon type="close" style="color: #b2b2b2"
                    /></a>
                  </div>
                </div>
              </a-list-item>
            </a-list>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="backClick">返回</a-button>
      <a-button v-if="!isViewModel" type="primary" @click="keepClick" :disabled="isDisabled"
        >保存</a-button
      >
    </FooterToolBar>

    <!-- 模态框 -->
    <CheckboxModal
      v-if="checkboxModalVisible"
      :modalVisible="checkboxModalVisible"
      :fuCourseSelectedRowKeys="fuCourseSelectedRowKeys"
      :fuMemberlectedRowKeys="fuMemberlectedRowKeys"
      :fuCourseRows="fuCourseRows"
      :fuMemberRows="fuMemberRows"
      :isShowMember="true"
      :showCostRow="false"
      @cancel="checkboxCancel"
      @ok="checkboxOk"
    />
  </div>
</template>

<script>
import moment from "moment";
import { baseMixin } from "@/store/app-mixin";
import { message } from "ant-design-vue";
import FooterToolBar from "@/components/FooterToolbar";
import CheckboxModal from "./modal/CheckboxModal";
import {
  AddCouponApi,
  EditCouponApi,
  GetCouponDetailApi
} from "@/request/api/marketingCenter";

export default {
    mixins: [baseMixin],
  data() {
    return {
      isGoods: true,
      isEdit: false, // 是否为编辑
      checkboxModalVisible: false, // 模态框的显示隐藏
      fuCourseSelectedRowKeys: [], // 课程选中courseNo数组
      fuMemberlectedRowKeys: [], // 会员已选择的memberNo数组
      fuCourseRows: [], // 选中课程对象数组
      fuMemberRows: [], // 选中课程对象数组
      modalVisible: false, // 模态框的显示隐藏
      isDisabled: false, // 保存的按钮是否禁用
      isViewModel: false, // 是否是查看模式
      goodsNo: [], // 传回后端的商品ID的数组
      tableList: [], // 商品列表数组
      rangePicker: [],
      rules: {
        couName: [
          { required: true, message: "请填写优惠券名称", trigger: "blur" },
        ],
        couPrice: [
          { required: true, message: "请填写优惠券金额", trigger: "blur" },
        ],
        couponLimitPrice: [
          { required: true, message: "请填写优惠券限制金额", trigger: "blur" },
        ],
        canUseCourseNos: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ]
      },
      couNo: '',
      isDisabled: false,
      form: {
        couNo: "",
        couName: "",
        couStartTime: "",
        couEndTime: "",
        couPrice: "",
        couponLimitPrice: "",
        couponOutDays: 0,
        couponCirculation: 0,
        types: 1,
        canUserNos: "",
        canUseCourseNos: [],
      },
    };
  },
  created() {
    this.couNo = this.$route.params.id;
    if (+this.couNo) {
      this.getCouponDetailData();
      if (this.$route.path.indexOf('couponManage/viewCoupon') > -1) {
        this.isViewModel = true;
      }
    }
  },
  components: {
      CheckboxModal,
      FooterToolBar
  },
  methods: {
    moment,
    getCouponDetailData() {
      GetCouponDetailApi({
        couNo: this.couNo
      }).then(({code, data}) => {
        this.form = Object.assign(this.form, {
          couNo: data.couponNo,
          couName: data.couponName,
          couStartTime: data.beginTime,
          couEndTime: data.endTime,
          couPrice: data.couponPrice,
          couponLimitPrice: data.couponLimitPrice,
          couponOutDays: data.couponOutDays,
          couponCirculation: data.couponCirculation,
          types: data.types,
          canUserNos: data.canUserNos,
          canUseCourseNos: data.canUseCourseNos.forEach(item => item.courseNo),
        });
        // 有效期
        this.rangePicker = [data.beginTime, data.endTime];
        // 已选商品
        this.tableList = data.canUseCourseNos;
      });
    },
    // 类型改变的回调
    typesChange(e) {
      this.form.types = e.target.value;
    },

    // 有效期改变的回调
    rangePickerChange(date, dateString) {
      if (dateString[0] != "") {
        this.form.couStartTime = dateString[0];
        this.form.couEndTime = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.form.couStartTime = "";
        this.form.couEndTime = "";
        this.rangePicker = [];
      }
    },

    // 添加商品的按钮
    addGoods() {
      this.checkboxModalVisible = true;
    },

    // 复选框的确定按钮
    checkboxOk(courseKeys, courseRows, memberKeys, memberRows) {
        console.log('## courseKeys', courseKeys, courseRows, memberKeys, memberRows)
      if (courseKeys.length > 0 || memberKeys.length > 0) {
        this.form.canUseCourseNos = courseKeys.concat(memberKeys);
        this.fuCourseSelectedRowKeys = courseKeys;
        this.fuMemberlectedRowKeys = memberKeys;
        this.tableList = courseRows.concat(memberRows);
        this.fuCourseRows = courseRows;
        this.fuMemberRows = memberRows;
      }
      this.checkboxModalVisible = false;
    },

    // 复选框的取消按钮
    checkboxCancel() {
      this.checkboxModalVisible = false;
    },

    // 返回的按钮
    backClick() {
      this.$router.go(-1);
    },

    // 选中数据后table 删除事件
    delHandle(record) {
      let num = record.courseNo ? record.courseNo : record.memberNo;
      this.tableList.forEach((item, index) => {
        if (item.courseNo == num) {
          this.tableList.splice(index, 1);
          this.form.canUseCourseNos.splice(index, 1);
        } else if (item.memberNo == num) {
          this.tableList.splice(index, 1);
          this.form.canUseCourseNos.splice(index, 1);
        }
      });
      this.fuCourseSelectedRowKeys.forEach((item, index) => {
        if (item == num) {
          this.fuCourseSelectedRowKeys.splice(index, 1);
          this.fuCourseRows.splice(index, 1);
        }
      });
      this.fuMemberlectedRowKeys.forEach((item, index) => {
        if (item == num) {
          this.fuMemberlectedRowKeys.splice(index, 1);
          this.fuMemberRows.splice(index, 1);
        }
      });
    },
    save() {
      const id = this.couNo;
      if (id == "0") {
        AddCouponApi({
          ...this.form,
          canUserNos: this.form.types === 1 ? '' : this.form.canUserNos,
          canUseCourseNos: this.form.canUseCourseNos.join(',')
        }).then(({ code }) => {
          if (code == 200) {
            message.success("保存成功", 2);
            location.href = "/marketingCenter/couponManage";
          }
        });
      } else {
        EditCouponApi({
          couNo: this.couNo,
          canUseCourseNos: this.form.canUseCourseNos.join(','),
          ...this.form,
        }).then(({ code }) => {
          if (code == 200) {
            message.success("保存成功", 2);
            location.href = "/marketingCenter/couponManage";
          }
        });
      }
    },

    keepClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>

.p-container {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
  padding: 10px 0;
  // background-color: #F2F2F2;
}
.info-wrap {
  width: 100%;
  padding: 0 20px;
  .item-info {
    margin-right: 14px;
    .p-img {
      width: 100px;
      height: 85px;
      object-fit: contain;
      border-radius: 2px;
    }
    .p-info {
      margin-left: 10px;
      .p-wrap {
        height: 67px;
      }
      .p-info-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #353535;
      }
      .p-info-price {
        font-size: 14px;
        color: #fb6161;
        margin-top: 4px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .p-info-type {
        font-size: 12px;
        color: #888;
        margin: 0 14px 0 0;
      }
    }
  }
}
</style>